import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";
import { useSelector } from "react-redux";
import config from "../../../config";
import axiosCall from "../../../lib/axios";
import { trueTime, duration } from "../../../lib/date";

const LivePublish = ({ language, signout }) => {
  ////////////// INITIALIZE //////////////////
  const navigate = useNavigate();
  const { state } = useLocation();

  console.log(state);
  const auth = useSelector((state) => state.authentication.value);
  const readOnly = auth.account_type === "free";

  const [publishing, setPublishing] = useState(false);
  const [dataChannel, setDataChannel] = useState(false);
  const [starting, setStarting] = useState(false);
  const [websocketConnected, setWebsocketConnected] = useState(false);
  const [websocketError, setWebsocketError] = useState(false);
  const [activeCount, setActiveCount] = useState(0);

  const [streamName, setStreamName] = useState(state?.streamName || "");
  const [streamStarted, setStreamStarted] = useState("");
  const [timestamp, setTimestamp] = useState(null);
  const [bitesArr, setBitesArr] = useState([]);
  const [messages, setMessages] = useState([]);
  const [messageCount, setMessageCount] = useState(0);
  const [micOff, setMicOff] = useState(false);
  const [camOff, setCamOff] = useState(false);
  const [error, setError] = useState(null);

  const videoRef = useRef();
  const camOffRef = useRef();
  const timerRef = useRef();
  const timeNow = useRef();
  const durationRef = useRef(0);

  const msgArr = useRef([]);
  const msgBox = useRef();
  const msgRef = useRef();
  const userRef = useRef({});
  const nameRef = useRef();
  const webRTCAdaptor = useRef(null);
  var publishedStreamId = useRef(null);

  useEffect(() => {
    if (!state?.pid) {
      // not kosher
      navigate("/livestreams");
    } else if (!webRTCAdaptor.current) {
      // initialize websocket
      initWebSocket();
    }
  }, []);

  useEffect(() => {
    if (!readOnly && websocketConnected) {
      //show video element
      camOffRef.current.style.opacity = 0;

      //post warning
      let warning = {
        message: language.labels.live.warning,
        glyph: "!",
      };
      popMessage(warning);

      //fix bites
      let arr = [];
      if (state?.bites) {
        for (var i = 0; i < state.selectedBites.length; i++) {
          const b = state.bites.filter((b) => b.bid === state.selectedBites[i]);
          arr.push(b[0]);
        }
      }
      setBitesArr(arr);
    }
  }, [websocketConnected]);

  useEffect(() => {
    if (!readOnly && websocketConnected) {
      // call api
      if (streamStarted) pauseApi("camOff");

      // relay message to data channel
      let messageObj = { type: "camOn" };
      if (camOff) messageObj.type = "camOff";
      if (websocketConnected && webRTCAdaptor.current)
        webRTCAdaptor.current.sendData(
          publishedStreamId.current,
          JSON.stringify(messageObj)
        );

      //fix display
      if (state.role !== "role_member") {
        if (camOff) {
          camOffRef.current.style.opacity = 1;
          camOffRef.current.style.backgroundImage = `url(${
            config.server.storage.bucket + userRef?.current.avatar
          })`;
        } else {
          camOffRef.current.style.opacity = 0;
          camOffRef.current.style.backgroundImage = "unset";
        }
      }
    }
  }, [camOff]);

  useEffect(() => {
    // call api
    if (streamStarted) pauseApi("micOff");

    // relay message to data channel
    let messageObj = { type: "micOn" };
    if (micOff) messageObj.type = "micOff";
    if (websocketConnected && webRTCAdaptor.current)
      webRTCAdaptor.current.sendData(
        publishedStreamId.current,
        JSON.stringify(messageObj)
      );
  }, [micOff]);

  useEffect(() => {
    //kick off timer
    if (timestamp) {
      console.log("TIMESTAMP", timestamp);
      timer();
    }
  }, [timestamp]);

  ////////////// WEBSOCKET //////////////////
  const initWebSocket = () => {
    webRTCAdaptor.current = new WebRTCAdaptor({
      websocket_url: config.server.live.websocket_url,
      mediaConstraints: {
        video: true,
        audio: true,
      },
      peerconnection_config: {
        iceServers: [{ urls: config.server.live.ice }],
      },
      sdp_constraints: {
        OfferToReceiveAudio: false,
        OfferToReceiveVideo: false,
      },
      localVideoId: "localVideo",
      // bandwidth: 900, // default is 900 kbps, string can be 'unlimited'
      dataChannelEnabled: true, // enable or disable data channel
      callback: (info, obj) => {
        switch (info) {
          case "pong":
            break;
          case "closed":
            webRTCAdaptor.current.stop(publishedStreamId.current);
            webRTCAdaptor.current.closeStream(publishedStreamId.current);
            webRTCAdaptor.current = null;
            window.location.href = "/livestreams";
            break;
          case "initialized":
            console.log(info);
            setWebsocketConnected(true);
            break;
          case "publish_started":
            console.log(info);
            const epochTime = new Date().getTime();
            setTimestamp(epochTime);
            setPublishing(true);
            setError(null);
            break;
          case "session_restored":
            console.log(info);
            setPublishing(true);
            break;
          case "data_channel_opened":
            setDataChannel(true);
            break;
          case "data_received":
            try {
              console.log(info);
              console.log("obj", obj);
              let msg = JSON.parse(obj.data);
              let event = msg.type || "";
              switch (event) {
                case "chat":
                  console.log(info);
                  popMessage(msg.chat);
                  break;
                case "count":
                  if (Number.isInteger(msg.count)) setActiveCount(msg.count);
                  break;
              }
            } catch (e) {
              console.log(info);
              console.log("obj", obj);
            }
            break;
          case "data_channel_closed":
            setDataChannel(false);
            break;
          case "publish_finished":
            console.log(info);
            setError(language.labels.live.connection_error);
            break;
          default:
            console.log(info);
            console.log(obj);
            break;
        }
      },
      callbackError: function (error, message) {
        console.log("ws callback error ", error);
        console.log(message);
        onSocketFail(error);
      },
    });
  };

  const onSocketFail = (error) => {
    console.log("websocket error ", error);
    console.log("websocket connected ", websocketConnected);
    if (!websocketConnected) {
      webRTCAdaptor.current = null;
      setWebsocketError(true);
    }
  };

  ////////////// PUBLISH //////////////////
  const prePublish = () => {
    if (state?.resume) {
      resumePublish(state?.resume);
    } else {
      initPublish();
    }
  };

  const initPublish = async () => {
    //init
    setError(null);
    let data = {
      type: "publish",
      streamType: "learning",
    };

    if (!streamName.trim() || !state?.pathway || !state?.selectedBites) return;

    data.streamName = streamName.trim();
    data.pathway = state?.pid || "";
    data.bites = state?.selectedBites || [];
    if (camOff) data.camOff = camOff;
    if (micOff) data.micOff = micOff;

    // retrieve token and stream id
    setStarting(true);
    let result = await axiosCall("live/totp", data);
    if (result.success) {
      if (result.status === 200) {
        handlePublish(result.data);
      } else {
        setError(language.labels.live.already.publishing);
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      initPublish();
    } else {
      //refresh token expired or unknown error
      console.log("error init pub ", result);
      // signout();
    }
  };

  const resumePublish = async (streamId) => {
    // retrieve token
    setError(null);
    setStarting(true);
    //can't resume with mic or cam off
    let data = { streamId };
    if (micOff) data.micOff = micOff;
    if (camOff) data.camOff = camOff;
    let result = await axiosCall("live/resume", data);
    if (result.success) {
      if (result.status === 200) {
        handlePublish(result.data);
      } else {
        setError(language.labels.live.already.publishing);
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      resumePublish(streamId);
    } else {
      //refresh token expired or unknown error
      console.log("error resume pub ", result);
      // signout();
    }
  };

  const handlePublish = async (obj) => {
    try {
      let usr = { username: obj.username, avatar: obj.avatar };
      if (obj.fullName) usr.fullName = obj.fullName;
      userRef.current = usr;
      durationRef.current = obj.duration;
      publishedStreamId.current = obj.streamId;
      webRTCAdaptor.current.publish(
        obj.streamId,
        "",
        obj.subscriberId,
        obj.subscriberCode,
        streamName.trim()
      );
      timeNow.current = parseInt(obj.timeNow);
      setStreamStarted(obj.started);
      if (obj.viewers) setActiveCount(obj.viewers);
    } catch (err) {
      setError(language.labels.error.unknown);
    }
  };

  const quitStream = async () => {
    let result = await axiosCall("live/quit", {
      streamId: publishedStreamId.current,
    });
    if (result.success) {
      closeStream();
    } else if (result.refresh) {
      //token has been refreshed, try again
      quitStream();
    } else {
      //refresh token expired or unknown error
      console.log("error quit stream ", result);
      // signout();
    }
  };

  const closeStream = () => {
    if (webRTCAdaptor.current) {
      webRTCAdaptor.current.closeWebSocket();
    } else {
      window.location.href = "/livestreams";
    }
  };

  ////////////// PAUSE & MUTE //////////////////
  const toggleMic = () => {
    if (micOff) {
      webRTCAdaptor.current.unmuteLocalMic();
      setMicOff(false);
    } else {
      webRTCAdaptor.current.muteLocalMic();
      setMicOff(true);
    }
  };

  const toggleCam = async () => {
    let data = { streamId: publishedStreamId.current };
    if (!camOff) data.status = true;

    if (camOff) {
      webRTCAdaptor.current.turnOnLocalCamera();
      setCamOff(false);
      videoRef.current.parentNode.style.height = "unset";
      videoRef.current.style.minHeight = "0px";
    } else {
      let height = videoRef.current.parentNode.offsetHeight;
      videoRef.current.style.minHeight = height + "px";
      videoRef.current.parentNode.style.height = height + "px";
      camOffRef.current.style.marginTop = height / 2 - 90 + "px";
      webRTCAdaptor.current.turnOffLocalCamera();
      setCamOff(true);
    }
  };

  const pauseApi = async (attribute) => {
    let data = { streamId: publishedStreamId.current, attribute };
    if (attribute === "micOff") {
      data.status = micOff;
    } else {
      data.status = camOff;
    }
    let result = await axiosCall("live/pause", data);
    if (result.refresh) {
      //token has been refreshed, try again
      pauseApi(attribute);
    } else if (!result.success) {
      //refresh token expired or unknown error
      console.log("error pause api ", result);
      // signout();
    }
  };

  ////////////// TIMER  //////////////////
  const timer = () => {
    if (window.location.pathname === "/pubstream") {
      let milliseconds = timeNow.current - parseInt(timestamp);
      const elapsed = (durationRef.current + milliseconds) / 1000;
      timerRef.current.innerText = duration(Math.max(elapsed, 0));
      if (streamStarted) {
        setTimeout(() => {
          timeNow.current = timeNow.current + 1000;
          timer();
        }, 1000);
      }
    }
  };

  ////////////// CHAT //////////////////
  const sendMessage = () => {
    let msg = msgRef.current.value.trim();
    if (msg) postMessage(msg);
  };

  const postMessage = (message) => {
    if (publishedStreamId.current) {
      const messageObj = {
        type: "chat",
        chat: {
          message,
          username: userRef?.current.username,
          avatar: userRef?.current.avatar,
          host: true,
        },
      };
      webRTCAdaptor.current.sendData(
        publishedStreamId.current,
        JSON.stringify(messageObj)
      );
      popMessage(messageObj.chat);
      msgRef.current.value = "";
    }
  };

  const popMessage = (message) => {
    //put message at start of array
    msgArr.current.unshift(message);
    setMessages(msgArr.current);
    setMessageCount(msgArr.current.length);
  };

  ////////////// RENDER GUI //////////////////
  const chat_map = messages.map((message, index) => {
    return (
      <div
        key={index}
        style={{
          display: "flex",
          marginTop: "0.5em",
          gap: "0.5em",
        }}
      >
        {message.avatar && (
          <div
            className="avatar medium"
            style={{
              flexShrink: 0,
              backgroundImage: `url("${
                config.server.storage.bucket + message.avatar
              }")`,
            }}
          ></div>
        )}
        {message.glyph && (
          <div
            className="avatar medium glyphs alert"
            style={{
              fontSize: "1.2em",
              flexShrink: 0,
              textAlign: "center",
            }}
          >
            {message.glyph}
          </div>
        )}
        <div style={{ fontSize: "0.9em" }}>
          <div style={{ display: "flex", gap: "0.5em" }}>
            {message.username && (
              <div style={{ fontWeight: 500 }}>{message.username}</div>
            )}
            {message.host && <div>{language.labels.live.role_host}</div>}
            {message.expert && <div>{language.labels.live.role_expert}</div>}
          </div>
          <div
            className="quote-text"
            style={{ marginTop: `${message.glyph ? "0" : "0.2em"}` }}
          >
            {message.message}
          </div>
        </div>
      </div>
    );
  });

  if (state.role === "role_member") {
    return <></>;
  } else {
    return (
      <div
        className="page-section"
        style={{
          paddingBottom: "6em",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="live-container">
          {/* HEADINGS */}
          <h1 className="heading" style={{ textAlign: "center" }}>
            {language.labels.live.learning_stream}
          </h1>

          {readOnly ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "1em 0",
                flexDirection: "column",
              }}
            >
              <div style={{ margin: "1em", textAlign: "center" }}>
                {language.labels.live.subscription_only}
              </div>
              <button
                className="button"
                onClick={() => navigate("/livestreams")}
              >
                {language.labels.app.ok}
              </button>
            </div>
          ) : (
            <>
              {websocketConnected ? (
                <>
                  {/* live status */}
                  <div
                    className={
                      streamStarted
                        ? "alert"
                        : publishing
                        ? "amber flashing"
                        : "inert"
                    }
                    style={{
                      fontWeight: "bold",
                      margin: "1em",
                      textAlign: "center",
                    }}
                  >
                    {streamStarted
                      ? language.labels.live.streaming.on
                      : publishing
                      ? language.labels.live.streaming.registering
                      : language.labels.live.streaming.off}
                  </div>

                  {error && (
                    <div
                      className="errtext"
                      role="alert"
                      style={{ margin: "-0.5em 0 1em", textAlign: "center" }}
                    >
                      {error}
                    </div>
                  )}

                  {publishing ? (
                    // stream name, viewers and start time
                    <div
                      style={{ margin: "-0.5em 0 1em", textAlign: "center" }}
                    >
                      <div
                        className="contrast"
                        style={{ marginBottom: "0.5em", fontSize: "1.1em" }}
                      >
                        {streamName}
                      </div>
                      <div>
                        {activeCount === 1
                          ? language.labels.live.viewers.singular
                          : language.labels.live.viewers.plural.replace(
                              /{n}/g,
                              activeCount
                            )}
                      </div>
                      {streamStarted && (
                        <div style={{ marginTop: "0.5em" }}>
                          {language.labels.live.started.replace(
                            /{date}/g,
                            trueTime(streamStarted, language.locale)
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    // input stream name, start, cancel
                    <>
                      <div style={{ textAlign: "center" }}>
                        <input
                          style={{
                            width: "100%",
                            maxWidth: "640px",
                            textAlign: "center",
                          }}
                          type="text"
                          ref={nameRef}
                          placeholder={language.labels.live.enter_name}
                          value={streamName}
                          onChange={(e) => setStreamName(e.target.value)}
                          disabled={state?.streamName}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "1em 0",
                          gap: "1em",
                        }}
                      >
                        <button
                          className="button"
                          disabled={starting || !streamName.trim()}
                          onClick={prePublish}
                          style={{
                            opacity: `${
                              !starting && streamName.trim() ? 1 : 0.5
                            }`,
                          }}
                        >
                          {state?.resume
                            ? language.labels.live.resume
                            : language.labels.live.start_session}
                        </button>
                        <button
                          className="button-secondary"
                          onClick={closeStream}
                        >
                          {language.labels.app.cancel}
                        </button>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className={websocketError ? "alert" : "flashing"}
                    style={{ margin: "1em 0 2em", textAlign: "center" }}
                  >
                    {websocketError
                      ? language.labels.error.unknown_later
                      : language.labels.live.connecting}
                  </div>
                  {websocketError && (
                    <button
                      className="button"
                      onClick={() => navigate("/livestreams")}
                    >
                      {language.labels.app.ok}
                    </button>
                  )}
                </div>
              )}

              {/********* STAGE *********/}
              <div
                className="live-stage"
                style={{ opacity: `${websocketConnected ? 1 : 0}` }}
              >
                {/* video */}
                <div className="live-video">
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      width: "100%",
                    }}
                  >
                    <div ref={camOffRef} className="thumb-paused"></div>
                    {camOff && (
                      <div
                        className="font-white"
                        style={{ textAlign: "center", marginTop: "0.2em" }}
                      >
                        {userRef.current.fullName || userRef.current.username}
                      </div>
                    )}
                  </div>

                  <video
                    id="localVideo"
                    ref={videoRef}
                    controls={false}
                    autoPlay={true}
                    muted={true}
                    style={{
                      display: "block",
                      transform: "scaleX(-1)",
                      opacity: `${camOff ? 0 : 1}`,
                      transition: "min-height 500ms ease-in-out",
                    }}
                  ></video>

                  {/* video timer */}
                  <div
                    className="live-heading"
                    style={{
                      justifyContent: "center",
                      opacity: `${timestamp ? 1 : 0}`,
                    }}
                  >
                    <div className="timer" ref={timerRef}></div>
                  </div>

                  {/* video control buttons */}
                  <div className="live-control">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "1em",
                      }}
                    >
                      {publishing && (
                        <>
                          {/* mic */}
                          <button
                            className={
                              micOff
                                ? "glyphs live-button off"
                                : "glyphs live-button on"
                            }
                            onClick={toggleMic}
                          >
                            {micOff ? '"' : "M"}
                          </button>

                          {/* pause/resume */}
                          <button
                            className={
                              camOff
                                ? "glyphs live-button off"
                                : "glyphs live-button on"
                            }
                            onClick={toggleCam}
                          >
                            {camOff ? "W" : "U"}
                          </button>

                          {/* stop */}
                          <button
                            className="glyphs live-button stop"
                            onClick={quitStream}
                          >
                            ]
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {/* chat box */}
                <div className="live-panel">
                  <div
                    className="live-panel-box"
                    ref={msgBox}
                    style={{
                      position: "absolute",
                      display: "flex",
                      padding: "0.5em",
                      flexDirection: "column-reverse",
                      gap: "0.5em",
                      overflowY: "auto",
                      bottom: "50px",
                      height: "Calc(100% - 50px)",
                    }}
                  >
                    {chat_map}
                  </div>
                  {/* message input */}
                  <div className="live-control message">
                    {/* panel control buttons */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "0 0.5em",
                        gap: "0.25em",
                        width: "100%",
                      }}
                    >
                      <input
                        type="text"
                        ref={msgRef}
                        style={{
                          opacity: `${dataChannel ? 1 : 0.6}`,
                          width: "Calc(100% - 32px)",
                        }}
                        disabled={!dataChannel}
                        placeholder={language.labels.live.enter_message}
                        onKeyUpCapture={(e) =>
                          e.key === "Enter" && sendMessage()
                        }
                      ></input>
                      {/* send */}
                      <button
                        aria-label={language.labels.aria.send_message}
                        disabled={!dataChannel}
                        className="glyphs live-button on"
                        style={{ opacity: `${dataChannel ? 1 : 0.3}` }}
                        onClick={() => sendMessage()}
                      >
                        P
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/********* FOCUS  *********/}
              <div
                className="card-long"
                style={{
                  display: "block",
                  textAlign: "center",
                  paddingBottom: "2em",
                  opacity: `${websocketConnected ? 1 : 0}`,
                }}
              >
                <h2
                  className="heading2"
                  style={{ fontWeight: 300, fontSize: "1.2em" }}
                >
                  {language.labels.live.focus}
                </h2>
                {/* pathway */}
                <div
                  style={{
                    display: "flex",
                    gap: "0.5em",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "1.5em 0 1em",
                  }}
                >
                  <div
                    role="img"
                    className="thumb-glyph tiny auto-margin-narrow"
                  >
                    p
                  </div>
                  <div style={{ fontWeight: 500 }}>{state?.pathway}</div>
                </div>

                {/* bites */}
                {bitesArr.map((bite, index) => (
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5em",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "0.5em",
                    }}
                  >
                    <div
                      role="img"
                      className="thumb-block border tiny auto-margin-narrow"
                      style={{
                        backgroundImage: `url("${
                          config.server.storage.bucket + bite.thumb
                        }")`,
                      }}
                    ></div>
                    <div className="quote-text" style={{ fontSize: "0.9em" }}>
                      {bite.title}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
};

export default LivePublish;
