import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import { Search } from "./";

import config from "../../config";
import axiosCall from "../../lib/axios";
import validator from "../../lib/validation";
import { naturalDate, duration, recentDate } from "../../lib/date";

const ContentList = ({
  language,
  api,
  community,
  content,
  page_title,
  new_link,
  thumb_height,
  per_page,
  pageHeight,
  account,
  setDisplayModal,
  signout,
}) => {
  //////////// INITIALIZATION ///////////
  const navigate = useNavigate();
  let searchTimer;

  const searchLabel = useRef();
  const pageRef = useRef();
  const bottomRef = useRef();
  const loadingRef = useRef();
  const hasMoreRef = useRef();

  const level = config.levels.indexOf(community.role);

  const [items, setItems] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    //ini refs
    pageRef.current = 0;
    loadingRef.current = false;
    hasMoreRef.current = true;

    //get first batch
    getBatch({
      page: 0,
      limit: per_page,
    });

    //listen to scroll
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  //////////// SCROLLING ///////////
  const handleScroll = () => {
    //check if within 200px of top
    if (bottomRef.current) {
      if (bottomRef.current.getBoundingClientRect().top < pageHeight + 200) {
        let params = { page: pageRef.current, limit: per_page };
        if (searchTerm.trim() !== "") params.search = searchTerm;
        getBatch(params);
      }
    }
  };

  //////////// SEARCH FUNCTIONS ///////////
  const checkSearch = () => {
    pageRef.current = 0;
    hasMoreRef.current = true;
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      if (searchTerm.trim() !== "") {
        // filter = { type: status, posted };
        getBatch(
          {
            page: pageRef.current,
            limit: per_page,
            search: searchTerm,
          },
          true
        );
      }
    }, 500);
  };

  const setSearch = (term) => {
    setSearchTerm(term);
    if (term === "") {
      clearTimeout(searchTimer);
      resetSearch();
    }
  };
  //retrieve a page
  const getBatch = async (data, force = false) => {
    //only call api if not loading and has more unless forced via refresh token
    if ((loadingRef.current || !hasMoreRef.current) && !force) return;
    loadingRef.current = true;

    //call api
    let result = await axiosCall(api, data, false);
    loadingRef.current = false;

    if (result.success) {
      if (data.page === 0) {
        setSearchResults(result.data);
        if (result.data.length > 0) setItems(true);
      } else {
        setSearchResults((prevData) => [...prevData, ...result.data]);
      }

      // fix search labels
      if (data.search) {
        console.log(data.search);
        if (result.data.length > 1) {
          searchLabel.current.innerText = language.labels[
            content
          ].search_results_n.replace(/{term}/g, data.search);
        } else if (result.data.length > 0) {
          searchLabel.current.innerText = language.labels[
            content
          ].search_results_1.replace(/{term}/g, data.search);
        } else {
          searchLabel.current.innerText = language.labels[
            content
          ].search_results_0.replace(/{term}/g, data.search);
        }
      } else {
        if (searchLabel.current)
          searchLabel.current.innerText = language.labels[content].all_date;
      }

      //fix refs
      if (result.data.length === 0 || result.data.length < per_page)
        hasMoreRef.current = false;
      pageRef.current = pageRef.current + 1;
    } else if (result.refresh) {
      //token has been refreshed, try again
      getBatch(data, true);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const resetSearch = () => {
    pageRef.current = 0;
    setSearchTerm("");
    hasMoreRef.current = true;
    per_page = Math.ceil(window.innerHeight / thumb_height);
    getBatch({
      page: 0,
      limit: per_page,
    });
  };

  //////////// CONTENT NAVIGATION ///////////
  const actionItem = (item) => {
    switch (content) {
      case "videos":
        editVideo(item);
        break;
      case "bites":
        getInfo(item._id);
        break;
      default:
        displayThread(item);
    }
  };

  const editVideo = (vid) => {
    if (account === "free" || level < 1) {
      setDisplayModal(true);
    } else {
      if (vid.status !== "processing") {
        navigate("/video", { state: { params: vid } });
      }
    }
  };

  const getInfo = (id) => {
    navigate("/bite", { state: { bid: id } });
  };

  const displayThread = (thread) => {
    if (account === "free" || level < 1) {
      setDisplayModal(true);
    } else {
      navigate("/thread", { state: { thread } });
    }
  };

  //////////// KEY NAVIGATION ///////////
  //navigate list with keys
  const bumpPos = (contents, direction) => {
    //find current position in list
    let pos = -1;
    var j = 0,
      len = contents.length;
    while (j < len) {
      if (contents[j] === document.activeElement) {
        pos = j;
        j = len;
      }
      j++;
    }
    pos = +pos + direction;
    if (pos < 0) pos = 0;
    if (pos >= len) pos = len - 1;
    contents[pos].focus();
  };
  const navResults = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      let direction = 1;
      if (e.key === "ArrowUp") direction = -1;
      let container = document.getElementById("Results");
      let contents = container.querySelectorAll("div.card-long");
      bumpPos(contents, direction);
    }
  };

  //////////// RENDER GUI ///////////
  const mappedResults = searchResults.map((item, index) => (
    <div
      role="figure"
      key={index}
      className="card-long hover"
      onClick={() => actionItem(item)}
      onKeyDown={(e) => navResults(e)}
      tabIndex={0}
      {...(content !== "videos" && {
        "aria-label": item.title,
      })}
    >
      {content === "videos" ? (
        <>
          <div
            className="auto-margin-narrow"
            style={{
              position: "relative",
              flexShrink: "0",
              width: "120px",
              height: "160px",
              overflow: "hidden",
            }}
          >
            {item.status === "processing" ? (
              <span
                role="button"
                aria-label={language.labels.aria.video_select}
                className="thumb-vid unavailable"
                style={{ cursor: "pointer", width: "120px", height: "160px" }}
                onKeyDown={(e) => e.key === "Enter" && editVideo(item)}
                tabIndex="0"
              >
                <div style={{ fontWeight: "500" }}>
                  {language.labels.videos.uploading.processing}
                </div>
                <div style={{ marginTop: "1em", fontSize: "0.8em" }}>
                  {language.labels.videos.uploaded.replace(
                    /{time}/g,
                    naturalDate(
                      item.created,
                      language.locale,
                      language.labels.date
                    )
                  )}
                </div>
              </span>
            ) : (
              <video
                role="link"
                aria-label={language.labels.aria.video_go}
                style={{
                  width: `${
                    3 * item.aspect < 4 ? "120px" : 160 * item.aspect + "px"
                  }`,
                  marginTop: `${
                    3 * item.aspect < 4 ? 60 * item.aspect - 60 + "px" : "0"
                  }`,
                  marginLeft: `${
                    3 * item.aspect < 4 ? "0" : 80 - 80 * item.aspect + "px"
                  }`,
                }}
                muted
                loop
                autoPlay
                src={config.server.storage.cache + item.preview}
                onKeyDown={(e) => e.key === "Enter" && editVideo(item)}
                tabIndex={0}
              ></video>
            )}
          </div>

          <div className="block-info">
            <div
              style={{
                display: "flex",
                gap: "0.25em",
                alignItems: "center",
                fontWeight: "500",
                fontSize: "1.1em",
                marginBottom: "0.5em",
              }}
            >
              <div
                className="glyphs font-contrast-soft"
                style={{ fontSize: "1.2em" }}
                aria-hidden="true"
                block={item.block}
              >
                j
              </div>
              <div className="clamped one" block={item.block}>
                {item.bite_name}
              </div>
            </div>
            <div style={{ fontSize: "0.8em", margin: "0.2em 0" }}>
              {naturalDate(item.created, language.locale, language.labels.date)}
            </div>

            {item.poster_endorsed && (
              <div
                style={{ fontSize: "11px" }}
                dangerouslySetInnerHTML={{
                  __html: language.labels.app.posted_expert.replace(
                    /{user}/g,
                    item.poster_name
                  ),
                }}
              />
            )}
            {!item.poster_endorsed && (
              <div
                style={{ fontSize: "11px" }}
                dangerouslySetInnerHTML={{
                  __html: language.labels.app.posted_by.replace(
                    /{user}/g,
                    item.poster_name
                  ),
                }}
              />
            )}
            <div
              className="font-soft"
              style={{ fontSize: "0.75em", margin: "0.5em 0" }}
            >
              {item.status === "processing"
                ? language.labels.videos.uploading.processing
                : language.labels.videos.duration.replace(
                    /{time}/g,
                    duration(item.duration)
                  )}
            </div>
            {item.description && (
              <div
                style={{ fontSize: ".9em", margin: "0.25em 0" }}
                className="clamped one"
              >
                {item.description}
              </div>
            )}
            <div
              style={{
                display: "flex",
                marginTop: "0.5em",
                alignItems: "center",
                opacity: "0.8",
              }}
            >
              <div
                role="img"
                className="glyphs"
                style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
                aria-label={language.labels.aria.watches}
                data-tooltip-id="watch-tooltip"
                data-tooltip-content={language.labels.aria.watches}
              >
                u
              </div>
              <div style={{ fontSize: "0.8em" }}>
                {validator.bigNumber(item.views)}
              </div>
              <div style={{ flexBasis: "2em" }}></div>
              <div
                role="img"
                className="glyphs"
                style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
                aria-label={language.labels.aria.likes}
                data-tooltip-id="like-tooltip"
                data-tooltip-content={language.labels.aria.likes}
              >
                O
              </div>
              <div style={{ fontSize: "0.8em" }}>
                {validator.bigNumber(item.likes)}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              backgroundImage: `url(${config.server.storage.bucket}${
                content === "bites" ? item.thumb : item.bite_image
              })`,
              flexShrink: "0",
            }}
            className="thumb-block auto-margin-narrow"
          ></div>

          <div className="block-info">
            <div
              role="link"
              className="clamped one"
              style={{ fontWeight: "500", fontSize: "1.1em" }}
              onKeyDown={(e) => e.key === "Enter" && actionItem(item)}
              tabIndex={0}
            >
              {item.title}
            </div>

            {content === "bites" ? (
              <>
                <div style={{ fontSize: "0.8em", margin: "0.2em 0" }}>
                  {naturalDate(
                    item.last_updated,
                    language.locale,
                    language.labels.date
                  )}
                </div>
                <div
                  style={{ fontSize: ".9em", marginTop: "0.5em" }}
                  className="clamped two"
                >
                  {item.about}
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "0.5em",
                    alignItems: "center",
                    opacity: "0.8",
                  }}
                >
                  <div
                    role="img"
                    className="glyphs"
                    style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
                    aria-label={language.labels.aria.bookmarks}
                    data-tooltip-id="followers-tooltip"
                    data-tooltip-content={language.labels.aria.bookmarks}
                  >
                    q
                  </div>
                  <div style={{ fontSize: "0.8em" }}>
                    {validator.bigNumber(item.count_bookmarks || 0)}
                  </div>
                  <div style={{ flexBasis: "2em" }}></div>
                  <div
                    role="img"
                    className="glyphs"
                    style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
                    aria-label={language.labels.navigation.videos}
                    data-tooltip-id="videos-tooltip"
                    data-tooltip-content={language.labels.navigation.videos}
                  >
                    v
                  </div>
                  <div style={{ fontSize: "0.8em" }}>
                    {validator.bigNumber(item.count_video)}
                  </div>
                  <div style={{ flexBasis: "2em" }}></div>
                  <div
                    role="img"
                    className="glyphs"
                    style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
                    aria-label={language.labels.navigation.knowledge}
                    data-tooltip-id="knowledge-tooltip"
                    data-tooltip-content={language.labels.navigation.knowledge}
                  >
                    k
                  </div>
                  <div style={{ fontSize: "0.8em" }}>
                    {validator.bigNumber(item.count_knowledge)}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    gap: "0.5em",
                    alignItems: "center",
                    margin: "0.25em 0",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  <div>
                    {language.labels.knowledge.from[item.knowledge_type]}
                  </div>
                  <div
                    className="clamped one font-soft"
                    style={{ fontStyle: "italic" }}
                  >
                    {item.bite_name}
                  </div>
                </div>
                <div style={{ fontSize: "0.8em", margin: "0.2em 0" }}>
                  {naturalDate(
                    item.created,
                    language.locale,
                    language.labels.date
                  )}
                </div>
                {item?.community === item.bite_community ||
                item.poster_endorsed ? (
                  <div className="handle" style={{ fontSize: "11px" }}>
                    {language.labels.app.expert}
                  </div>
                ) : (
                  <div
                    style={{ fontSize: "11px" }}
                    dangerouslySetInnerHTML={{
                      __html: language.labels.app.posted_by.replace(
                        /{user}/g,
                        item.poster_name
                      ),
                    }}
                  />
                )}

                <div
                  style={{ fontSize: ".9em", marginTop: "0.5em" }}
                  className={`quote-text${
                    item.status === "available" && " clamped two"
                  }`}
                >
                  {item.status === "available"
                    ? item.post
                    : item.status === "pending"
                    ? language.labels.knowledge.pending
                    : language.labels.app.images_processing}
                </div>

                <div
                  style={{
                    display: "flex",
                    marginTop: "0.5em",
                    alignItems: "center",
                    opacity: "0.8",
                  }}
                >
                  <div
                    role="img"
                    className="glyphs"
                    style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
                    aria-label={language.labels.aria.reads}
                    data-tooltip-id="read-tooltip"
                    data-tooltip-content={language.labels.aria.reads}
                  >
                    u
                  </div>
                  <div style={{ fontSize: "0.8em" }}>
                    {validator.bigNumber(item.views)}
                  </div>
                  <div style={{ flexBasis: "2em" }}></div>
                  <div
                    role="img"
                    className="glyphs"
                    style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
                    aria-label={language.labels.aria.likes}
                    data-tooltip-id="like-tooltip"
                    data-tooltip-content={language.labels.aria.likes}
                  >
                    O
                  </div>
                  <div style={{ fontSize: "0.8em" }}>
                    {validator.bigNumber(item.likes)}
                  </div>
                  {item.answers > 0 && (
                    <>
                      <div style={{ flexBasis: "2em" }}></div>
                      <div
                        role="img"
                        className="glyphs"
                        style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
                        aria-label={language.labels.aria.answers}
                        data-tooltip-id="answer-tooltip"
                        data-tooltip-content={language.labels.aria.answers}
                      >
                        *
                      </div>
                      <div style={{ fontSize: "0.8em" }}>
                        {validator.bigNumber(item.answers)}
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  ));

  return (
    <>
      {/* heading */}
      <div className="page-section" style={{ display: "flex" }}>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div
            role="img"
            aria-label={language.labels.aria.logo.replace(
              /{org}/g,
              community.title
            )}
            className="menu-crest"
            style={{
              height: "3em",
              width: "3em",
              backgroundImage: `url("${
                config.server.storage.bucket + community.crest
              }")`,
            }}
          ></div>
          <div style={{ width: "0.5em" }}></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "calc(100% - 2.5em)",
            }}
          >
            <h1 className="heading" style={{ width: "100%", padding: "0" }}>
              {page_title}
            </h1>
            <div className="handle2" style={{ width: "100%" }}>
              {community.title}
            </div>
          </div>
        </div>
      </div>

      {/* add new */}
      {level > 1 && (
        <div className="page-section">
          <div
            style={{
              display: "flex",
              gap: "0.5em",
              alignItems: "center",
              marginTop: "1em",
            }}
          >
            <button className="button-glyph" onClick={() => navigate(new_link)}>
              +
            </button>
            <div aria-hidden={true}>
              <span className="link" onClick={() => navigate(new_link)}>
                {language.labels[content].add}
              </span>
            </div>
          </div>
        </div>
      )}

      {/* search control */}
      {items ? (
        <div className="page-section">
          <Search
            search={searchTerm}
            onKey={checkSearch}
            onChange={setSearch}
            placeholder={language.labels[content].search}
          />
        </div>
      ) : (
        <div className="page-section">
          <div>{language.labels[content].none}</div>
          {level > 1 && (
            <button
              className="button"
              style={{ marginTop: "2em" }}
              onClick={() => navigate(new_link)}
            >
              {language.labels[content].add_now}
            </button>
          )}
        </div>
      )}

      <div
        className="page-section"
        style={{ maxWidth: "66rem", paddingBottom: "1em" }}
      >
        <h2
          ref={searchLabel}
          className="heading2"
          style={{
            fontSize: "0.9em",
            fontWeight: "500",
            margin: "0 0.25em",
          }}
        ></h2>

        {/* results */}
        <div id="Results">{mappedResults}</div>
        <div
          ref={bottomRef}
          style={{
            height: "50px",
            marginBottom: "1em",
            width: "100%",
            maxWidth: "60rem",
            textAlign: "center",
          }}
        >
          {hasMoreRef.current && (
            <div
              role="status"
              aria-label={language.labels.aria.loading}
              className="batch-loading glyphs"
            >
              Z
            </div>
          )}
        </div>
      </div>

      {/* tooltips */}
      <Tooltip id="answer-tooltip" />
      <Tooltip id="followers-tooltip" />
      <Tooltip id="knowledge-tooltip" />
      <Tooltip id="like-tooltip" />
      <Tooltip id="read-tooltip" />
      <Tooltip id="videos-tooltip" />
      <Tooltip id="watch-tooltip" />
    </>
  );
};

export default ContentList;
